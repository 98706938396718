<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/equipment-details/create`">
        <CButton color="success">Создать запчасть</CButton>
      </router-link>
      <br />
      <br />

      <CDataTable
        :items="activeEquipmentDetails"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter="{ label: 'Поиск', placeholder: 'введите код' }"
      >
        <template #code="{ item }">
          <td class="td" style="width: 50px">
            <router-link :to="`/equipment-details/${item.code}---${item.version}`">
              {{ item.code }}  v.{{ item.version }}
            </router-link>
          </td>
        </template>
        <template #archive="{ item }">
          <td class="td" style="width:10%;">
            <div v-if="item.archive">
                Архивная
            </div>
            <div v-else>
                Действующая
            </div>
          </td>
        </template>
        <template #description="{ item }">
          <td class="td" style="width: 150px">
            {{ item.description }}
          </td>
        </template>
        <template #price="{ item }">
          <td class="td" style="width: 150px">
            {{ item.price }}
          </td>
        </template>

        <template #model_preview="{ item }">
          <td style="width: 100px !important">
            <img
              class="image-showcase"
              :src="item.model_preview"
              style="width: 100px"
            />
          </td>
        </template>


        <template #weight="{item}">
          <td style="width: 100px !important">
            {{ item.weight }}
          </td>
        </template>

        <template #virtual="{ item }">
          <td class="td" style="width: 150px">
            <div v-if="item.virtual">ДА</div>
          </td>
        </template>
        <template #price_zink="{ item }">
          <td class="td" style="width: 150px">
            {{ item.price_zink }}
          </td>
        </template>

        <template #amount_per_detail="{ item }">
          <td class="td" style="width: 150px">
            <div>{{Math.round(getPackageAmountPerDetail(item)) }}</div>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
  text-align: right;
}

tr:nth-child(3) {
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import VueRouter from "vue-router";

const router = new VueRouter();

const fields = [
  {
    key: "code",
    label: "Код запчасти",
  },
  {
    key: "archive",
    label: "Статус",
  },
  {
    key: "description",
    label: "Описание",
  },
  {
    key: "price",
    label: "Цена",
  },
  {
    key: "model_preview",
    label: "Превью",
  },
  {
    key: "weight",
    label: "Вес, кг",
  },
  {
    key: "virtual",
    label: "Только для ТС",
  },
  {
    key: "price_zink",
    label: "Цена — цинк",
  },
  {
    key: "amount_per_detail",
    label: "Гофролист (мм)",
  },
];

export default {
  name: "EquipmentDetails",
  data() {
    return {
      fields,
    };
  },
  computed: {
    ...mapState({
      equipmentDetails: (state) => state.equipmentDetails.all,
      activeEquipmentDetails() {
        return this.equipmentDetails
        return this.equipmentDetails.filter((element) => !element.archive);
      },
    }),
  },
  created() {
    this["equipmentDetails/getAll"]();
    this["equipmentPackages/getAll"]();
  },
  methods: {
    ...mapActions(["equipmentDetails/getAll", "equipmentPackages/getAll"]),

    getPackageAmountPerDetail(equipmentDetail) {
      for (const testEquipmentPackage of equipmentDetail
        .equipment_packages) {
        if (testEquipmentPackage.equipment_package_id === 2) {
          return testEquipmentPackage.amount_per_detail || '';
        }
      }
      return ''
    },
  },
};
</script>
