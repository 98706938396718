var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCardBody',{staticClass:"c-card-body"},[_c('router-link',{attrs:{"to":"/equipment-details/create"}},[_c('CButton',{attrs:{"color":"success"}},[_vm._v("Создать запчасть")])],1),_c('br'),_c('br'),_c('CDataTable',{attrs:{"items":_vm.activeEquipmentDetails,"fields":_vm.fields,"hover":"","border":"","sorter":"","tableFilter":{ label: 'Поиск', placeholder: 'введите код' }},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"50px"}},[_c('router-link',{attrs:{"to":("/equipment-details/" + (item.code) + "---" + (item.version))}},[_vm._v(" "+_vm._s(item.code)+"  v."+_vm._s(item.version)+" ")])],1)]}},{key:"archive",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"10%"}},[(item.archive)?_c('div',[_vm._v(" Архивная ")]):_c('div',[_vm._v(" Действующая ")])])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(item.price)+" ")])]}},{key:"model_preview",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"width":"100px !important"}},[_c('img',{staticClass:"image-showcase",staticStyle:{"width":"100px"},attrs:{"src":item.model_preview}})])]}},{key:"weight",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"width":"100px !important"}},[_vm._v(" "+_vm._s(item.weight)+" ")])]}},{key:"virtual",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"150px"}},[(item.virtual)?_c('div',[_vm._v("ДА")]):_vm._e()])]}},{key:"price_zink",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(item.price_zink)+" ")])]}},{key:"amount_per_detail",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td",staticStyle:{"width":"150px"}},[_c('div',[_vm._v(_vm._s(Math.round(_vm.getPackageAmountPerDetail(item))))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }